<template>
  <div>
    <div class="row">
      <div class="col-12">
        <!--begin::Mixed Widget 10-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div
            class="card-body d-flex align-items-center justify-content-between flex-wrap"
          >
            <div class="mr-2">
              <h3
                class="font-weight-bolder d-flex align-items-center flex-nowrap"
              >
                <div>
                  <img class="custom-logo" src="media/logos/shopscan.png" />
                </div>
                <div>ShopScan</div>
              </h3>
              <div class="text-dark-50 font-size-lg my-2">
                Optimisez la gestion de vos flux de commandes
              </div>
            </div>
            <a
              href="https://www.shopscan.be"
              target="_blank"
              class="btn btn-primary font-weight-bold py-3 px-6"
              >Plus d'infos</a
            >
          </div>
          <!--end::Body-->
        </div>
        <!--end::Mixed Widget 10-->
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <!--begin::Tiles Widget 13-->
        <div
          class="card card-custom bgi-no-repeat gutter-b"
          style="height: 175px; background-color: white; background-position: calc(100% + 0.5rem) 100%; background-size: 80% auto;"
          :style="{
            backgroundImage: `url(${backgroundImage(
              'media/svg/patterns/taieri.svg'
            )})`
          }"
        >
          <!--begin::Body-->
          <div class="card-body d-flex align-items-center">
            <div>
              <h3
                class="font-weight-bolder d-flex align-items-center justify-content-between flex-wrap line-height-lg mb-5"
              >
                <div>
                  <img class="custom-logo" src="media/logos/snappyto.png" />
                </div>
                <div>Gestion <br />documentaire</div>
              </h3>
              <a
                href="https://www.snappyto.com"
                target="_blank"
                class="btn btn-warning font-weight-bold px-6 py-3"
                >Snappyto</a
              >
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Tiles Widget 13-->
        <div class="row">
          <div class="col-xl-6">
            <!--begin::Tiles Widget 11-->
            <div
              class="card card-custom bg-primary gutter-b"
              style="height: 150px"
            >
              <div class="card-body">
                <span class="svg-icon svg-icon-3x svg-icon-white ml-n2">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/Layout/Layout-4-blocks.svg-->
                  <inline-svg
                    src="media/svg/icons/Layout/Layout-4-blocks.svg"
                  />
                  <!--end::Svg Icon-->
                </span>
                <div
                  class="text-inverse-primary font-weight-bolder font-size-h2 mt-3"
                >
                  {{ stats.documentCount }}
                </div>
                <a
                  href="#"
                  class="text-inverse-primary font-weight-bold font-size-lg mt-1"
                  >Fichiers</a
                >
              </div>
            </div>
            <!--end::Tiles Widget 11-->
          </div>
          <div class="col-xl-6">
            <!--begin::Tiles Widget 12-->
            <div class="card card-custom gutter-b" style="height: 150px">
              <div class="card-body">
                <span class="svg-icon svg-icon-3x svg-icon-success">
                  <inline-svg src="media/svg/icons/Communication/Group.svg" />
                </span>
                <div class="text-dark font-weight-bolder font-size-h2 mt-3">
                  {{ stats.userCount }}
                </div>
                <a
                  href="#"
                  class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
                  >Utilisateurs</a
                >
              </div>
            </div>
            <!--end::Tiles Widget 12-->
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <!--begin::Mixed Widget 14-->
        <div
          class="card card-custom bgi-no-repeat bgi-size-cover gutter-b card-stretch"
          :style="{
            backgroundImage: `url(${backgroundImage(
              'media/stock-600x600/corellia.png'
            )})`
          }"
        >
          <!--begin::Body-->
          <div
            class="card-body d-flex flex-column align-items-start justify-content-start"
          >
            <div class="p-1 flex-grow-1">
              <h3 class="text-white font-weight-bolder line-height-lg mb-5">
                Besoin d'une <br />application <br />sur mesure ?
              </h3>
            </div>
            <a
              href="https://www.corellia.be"
              target="_blank"
              class="btn btn-link btn-link-warning font-weight-bold"
              >Contactez Corellia
              <span class="svg-icon svg-icon-lg svg-icon-warning">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg" />
                <!--end::Svg Icon-->
              </span></a
            >
          </div>
          <!--end::Body-->
        </div>
        <!--end::Mixed Widget 14-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Ads",
  props: {
    stats: Object
  },
  methods: {
    backgroundImage(path) {
      return process.env.BASE_URL + path;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
<style lang="scss" scoped>
.custom-logo {
  height: 40px;
}
</style>

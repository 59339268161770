<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-12 col-sm-6 col-md-5">
        <router-view></router-view>
      </div>
      <div class="col-12 col-sm-6 col-md-7">
        <Ads :stats="stats"></Ads>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Ads from "./Ads.vue";

export default {
  name: "Dashboard",
  components: {
    Ads
  },
  data() {
    return {
      stats: {}
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Central Core" }]);
    this.getStatistics();
  },
  methods: {
    async getStatistics() {
      const response = await this.$services.dashboardService.getStatistics();
      if (response && response.data) {
        this.stats = response.data;
      }
    }
  }
};
</script>

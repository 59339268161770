var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card card-custom bgi-no-repeat gutter-b",staticStyle:{"height":"175px","background-color":"white","background-position":"calc(100% + 0.5rem) 100%","background-size":"80% auto"},style:({
          backgroundImage: ("url(" + (_vm.backgroundImage(
            'media/svg/patterns/taieri.svg'
          )) + ")")
        })},[_vm._m(1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card card-custom bg-primary gutter-b",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-white ml-n2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Layout/Layout-4-blocks.svg"}})],1),_c('div',{staticClass:"text-inverse-primary font-weight-bolder font-size-h2 mt-3"},[_vm._v(" "+_vm._s(_vm.stats.documentCount)+" ")]),_c('a',{staticClass:"text-inverse-primary font-weight-bold font-size-lg mt-1",attrs:{"href":"#"}},[_vm._v("Fichiers")])])])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card card-custom gutter-b",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-success"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Group.svg"}})],1),_c('div',{staticClass:"text-dark font-weight-bolder font-size-h2 mt-3"},[_vm._v(" "+_vm._s(_vm.stats.userCount)+" ")]),_c('a',{staticClass:"text-muted text-hover-primary font-weight-bold font-size-lg mt-1",attrs:{"href":"#"}},[_vm._v("Utilisateurs")])])])])])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card card-custom bgi-no-repeat bgi-size-cover gutter-b card-stretch",style:({
          backgroundImage: ("url(" + (_vm.backgroundImage(
            'media/stock-600x600/corellia.png'
          )) + ")")
        })},[_c('div',{staticClass:"card-body d-flex flex-column align-items-start justify-content-start"},[_vm._m(2),_c('a',{staticClass:"btn btn-link btn-link-warning font-weight-bold",attrs:{"href":"https://www.corellia.be","target":"_blank"}},[_vm._v("Contactez Corellia "),_c('span',{staticClass:"svg-icon svg-icon-lg svg-icon-warning"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Arrow-right.svg"}})],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-body d-flex align-items-center justify-content-between flex-wrap"},[_c('div',{staticClass:"mr-2"},[_c('h3',{staticClass:"font-weight-bolder d-flex align-items-center flex-nowrap"},[_c('div',[_c('img',{staticClass:"custom-logo",attrs:{"src":"media/logos/shopscan.png"}})]),_c('div',[_vm._v("ShopScan")])]),_c('div',{staticClass:"text-dark-50 font-size-lg my-2"},[_vm._v(" Optimisez la gestion de vos flux de commandes ")])]),_c('a',{staticClass:"btn btn-primary font-weight-bold py-3 px-6",attrs:{"href":"https://www.shopscan.be","target":"_blank"}},[_vm._v("Plus d'infos")])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-body d-flex align-items-center"},[_c('div',[_c('h3',{staticClass:"font-weight-bolder d-flex align-items-center justify-content-between flex-wrap line-height-lg mb-5"},[_c('div',[_c('img',{staticClass:"custom-logo",attrs:{"src":"media/logos/snappyto.png"}})]),_c('div',[_vm._v("Gestion "),_c('br'),_vm._v("documentaire")])]),_c('a',{staticClass:"btn btn-warning font-weight-bold px-6 py-3",attrs:{"href":"https://www.snappyto.com","target":"_blank"}},[_vm._v("Snappyto")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1 flex-grow-1"},[_c('h3',{staticClass:"text-white font-weight-bolder line-height-lg mb-5"},[_vm._v(" Besoin d'une "),_c('br'),_vm._v("application "),_c('br'),_vm._v("sur mesure ? ")])])}]

export { render, staticRenderFns }